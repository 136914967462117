<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
     <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">

         <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(action)="data">
                  <b-dropdown bottom variant="info">
                                   <template #button-content>
                                         <i class="el-icon-more"></i>
                                    </template>
                                  <template>
                                        <b-dropdown-item  id="toggle-btn"  v-b-modal.modal-1 @click="Edit(data.item)">
                                            <i class="el-icon-edit"></i> Duzenle
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="Delete(data.item)">
                                          <i class="el-icon-delete"></i> Sil
                                      </b-dropdown-item>
                                      
                                  </template>
                                
                            
                        </b-dropdown>
                </template>
                <template v-slot:cell(dogrulama)="data">
                    <b-alert show v-if="data.item.dogrulama=='0'" style="background-color:#fa87d4;color:white">Lisans Girilmemiş</b-alert>
                    <b-alert show v-else>Lisans Girilmiş</b-alert>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <b-modal id="modal-1" v-if="modaldata!=null"  title="Müşteri Düzenle" hide-footer>
            <div class="row">
              <div class="col-md-6">
                  Müşteri
                  <b-form-input id="input-small" size="sm" v-model="modaldata.firmaadi" placeholder="Müşteri"></b-form-input>
                  <br>
                  E-mail
                  <b-form-input id="input-small" size="sm" v-model="modaldata.email" placeholder="E-mail"></b-form-input>
                  <br>
                  Kullanıcı Sınırı
                  <b-form-input id="input-small" size="sm" v-model="modaldata.kullanicisiniri" placeholder="Kullanıcı Sınırı"></b-form-input>
                  <br>
                  Key
                  <b-form-input id="input-small" size="sm" v-model="modaldata.key" placeholder="Key"></b-form-input><b-button variant="danger" size="sm" @click="generateKey">Generate Key</b-button>
                  <br>
                  Bayi
                  <b-form-input id="input-small" size="sm" v-model="modaldata.bayi" placeholder="Bayi"></b-form-input>
                  <br>                 

              </div>
              <div class="col-md-6">
                Uzak İp 
                <b-form-input id="input-small" size="sm" v-model="modaldata.uzakip" placeholder="Uzak ip"></b-form-input>
                  <br>
                  Cihaz Sayisi
                <b-form-input id="input-small" size="sm" v-model="modaldata.lisans_sayisi" placeholder="Lisans Sayısı"></b-form-input>
                  <br>
                  Lisans Bitiş Tarihi
                <b-form-datepicker id="example-datepicker" v-model="modaldata.lisanstarihi" placeholder="Lisans Bitiş" size="sm" class="mb-2"></b-form-datepicker>
                <br>
                <b-form-group label="" v-slot="{ ariaDescribedby2 }">
                         <b-form-radio-group
                                 v-model="modaldata.dogrulama"
                                 v-if="yetki=='admin'"
                                 :options="dogrulamaoptions"
                                 :aria-describedby="ariaDescribedby2"
                                 name="radio-inline2"
                         ></b-form-radio-group>
                     </b-form-group>
                 <b-button variant="outline-primary" @click="UpdateMusteri(modaldata)">Güncelle</b-button>
              </div>
            </div>
          </b-modal>
          </div>
        </div>
                   
        </div>
      </div>
     </div>
     
 </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
import moment from 'moment';
//import Swal from 'sweetalert2'
export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Eski Subgate Lisans Bilgileri",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Müşteriler",
            active: true
          }
        ],
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "action",
            label: "İşlemler"
          },{
            key: "dogrulama",
            sortable: true,
            label: "Lisans Durumu"
        },{
            key: "key",
            sortable: true,
            label: "Key"
        },{
            key: "email",
            sortable: true,
            label: "E-mail"
        },{
            key: "firmaadi",
            sortable: true,
            label: "Firma Adı"
        },{
            key: "kullanicisiniri",
            sortable: true,
            label: "Kullanıcı Sınırı"
          },
          {
            key: "lisans_sayisi",
            sortable: true,
            label: "Lisans Sayısı"
          },
          {
            key: "lisanstarihi",
            sortable: true,
            label: "Lisans Tarihi"
          },
          {
            key: "uzakip",
            sortable: true,
            label: "Uzak ip"
          },
          {
            key: "bayi",
            sortable: true,
            label: "Bayi"
          },
        ],
        modaldata:null,
        dogrulamaoptions:[
                 { text: 'Lisans Dogrulanmış', value: 1 },
                 { text: 'Lisans Dogrulanmamış', value: 0 },
             ]
         
              
      }
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
      yetki()
         {
           return this.$store.getters["auth/rl"];
         },   
    },
    created() {
      var vm = this;
      vm.List();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      UpdateMusteri(params)
      {
        var vm = this;
        try {
          axiosInstance.post("license/eski-lisans/guncelle",{
           firmaadi:params.firmaadi,
           lisanstarihi:moment(params.lisanstarihi).format("YYYY-MM-DD"),
           lisanssayisi:params.lisans_sayisi,
           email:params.email,
           bayi:params.bayi,
           kullanicisiniri:params.kullanicisiniri,
           key:params.key,
           id:params.id,
           dogrulama:params.dogrulama
          }).then((response)=>{

            if(response.data.error==true)
            {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast("Güncelleme başarılı,Lisans Güncellendi", {
                title: `Bildirim !`,
                variant: "info",
                solid: true
              });
              vm.List();
            }
          
          
          }).catch(error=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })

          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      },
      List()
      {
         var vm = this;
         vm.transactionData = [];
         try {

          axiosInstance.post("license/eski-lisans/listele").then((response)=>{

            console.log(response);

           if (response.data.error == true) {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
           }else{
              response.data.data.forEach(element => {                
                vm.transactionData.push({
                    bayi:element.bayi,
                    id:element.id,
                    dogrulama:element.dogrulama,
                    email:element.email,
                    firmaadi:element.firmaadi,
                    key:element.key,
                    kullanicisiniri:element.kullanicisiniri,
                    lisans_sayisi:element.lisans_sayisi,
                    lisanstarihi:element.lisanstarihi,
                    uzakip:element.uzakip,
                });
              });             
           }
           
          }).catch((error)=>{

            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
            
          })
          
         } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

         }
      },
      Edit(row) {
        this.modaldata = row;
        this.modaldata["lisanstarihi"] = moment(row.lisanstarihi).format("YYYY-MM-DD")
      },
      Delete(params) {
        var vm = this;
        try {

            if(confirm("Silmeyi Onaylıyor musunuz ?")) {
                axiosInstance.post("license/eski-lisans/sil",{
                    id:params.id
                }).then((response)=>{

                    if(response.data.error==true)
                    {
                        vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    }else{
                        vm.$bvToast.toast("Lisans Silinmiştir", {
                            title: `Bildirim !`,
                            variant: "info",
                            solid: true
                        });
                        vm.List();
                    }


                }).catch((error)=>{
                    vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                        title: `Uyari !`,
                        variant: "warning",
                        solid: true
                    });
                })
            }


          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      },
      generateKey()
      {
        var vm = this;

        try {
        

          axiosInstance.get("license/eski-lisans/sifrele").then((response)=>{

           if (response.data.error == true) {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
           }else{
                vm.$bvToast.toast("Key Oluşturuldu", {
                title: `Uyari !`,
                variant: "primary",
                solid: true
                });

                vm.modaldata.key = response.data.data.Key;
                       
           }
           
          }).catch((error)=>{

            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
            
          })
          
         } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

         }

      }
    },
}
</script>
<style lang="scss">
.modal-content{
  display: table-cell !important;
}
.swal-wide{
    width:850px !important;
}
    #modal-2___BV_modal_body_{
        padding:0px !important;
        table {
            tbody{
                tr{

                    td{
                        padding:0px !important;
                        &:before{
                            height: 50px !important;
                            background-color: #3385ff;
                            text-align: left !important;
                            padding-left:25px;
                            padding-top:10px;
                            color:white;
                        }
                    }

                    td[data-label="Lisans Key"]
                    {
                        div{
                            overflow: scroll;
                            width: 400px;
                            height: 43px;
                        }
                    }



                }
            }

        }
    }
</style>